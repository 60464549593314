<template>
  <div>
  <commonbanner></commonbanner>
  <div class="bor container Introduce">
    <div class="m-tel-key-block">
      <div class="key-block-intro">
        <div class="key-content">
          <div class="subtitle"><h2>热线介绍</h2><hr class="underline"></div>
          <div class="info">
            <p class="desc"> XX省公共法律服务平台是全省统一的公共法律服务平台，是司法行政部门拓宽与人民群众联系的渠道，运用现代信息技术为社会提供集窗口服务、电话服务和网上服务为一体的一站式综合法律服务平台。 </p>
            <p class="desc">“XX省12348公共法律服务热线”是XX省司法厅搭建的全省统一的公共法律服务热线 ，是将窗口服务、电话服务和网上服务整合为一体的一站式综合型法律服务平台。向广大群众解答法律咨询、宣传法律知识，内部联动法律服务机构，满足群众法律需求 </p>
            <p class="desc"> 拨打方式：<a href="tel:12348">12348</a> </p>
          </div>
        </div>
      </div>
      <div class="key-content">
        <div class="subtitle"><h2>12348热线按键提示</h2><hr class="underline"></div>
        <div class="info">
          <div class="key-item-week">
            <div class="key-item">
              <h4 class="item-tt">
                <a-tag color="blue">
                  7*24小时法律咨询 一至周五 09:00-17:00
                </a-tag>
              </h4>
            </div>
          </div>
          <div class="key-item-week">
            <div class="key-item">
              <div class="clearfix">
                <div class="item-bd key-bg-1 pull-left"> <span class="item-color-1">按1法律问题咨询</span>
                  <div class="cir key-cir-other key-cir-bg-1"> <span class="cir-item-bg-1">1</span> </div>
                </div>
                <div class="item-bd key-bg-2 pull-right"> <span>按2法律援助</span>
                  <div class="cir key-cir-other key-cir-bg-2"> <span class="cir-item-bg-2">2</span> </div>
                </div>
                <div class="item-bd key-bg-3 pull-left"> <span>按3律师服务</span>
                  <div class="cir key-cir-other key-cir-bg-3"> <span class="cir-item-bg-3">3</span> </div>
                </div>
                <div class="item-bd key-bg-3 pull-right" style="background-color:#E9F1FF "> <span>按4人民调解</span>
                  <div class="cir key-cir-other key-cir-bg-3" style="background-color: #ADC8FF"> <span class="cir-item-bg-3" style="background-color: #4F73DE">4</span> </div>
                </div>
                <div class="item-bd key-bg-5 pull-left"> <span>按5扶贫专线</span>
                  <div class="cir key-cir-other key-cir-bg-5"> <span class="cir-item-bg-5">5</span> </div>
                </div>
                <div class="item-bd key-bg-6 pull-right"> <span>按6防疫专线 </span>
                  <div class="cir key-cir-other key-cir-bg-6"> <span class="cir-item-bg-6">6</span> </div>
                </div>
                <div class="item-bd key-bg-7 pull-left"> <span>按7法律服务市场监督</span>
                  <div class="cir key-cir-other key-cir-bg-7"> <span class="cir-item-bg-1">7</span> </div>
                </div>
                <div class="item-bd key-bg-7 pull-right" style="background-color:#FFFBDF "> <span>按8投诉建议</span>
                  <div class="cir key-cir-other key-cir-bg-7" style="background-color:#FFD664 "> <span class="cir-item-bg-1" style="background-color:#FFBB1C ">8</span> </div>
                </div>
                <div class="item-bd key-bg-7 pull-left" style="background-color: #E8F9FF"> <span>按9其他业务</span>
                  <div class="cir key-cir-other key-cir-bg-7" style="background-color: #87D6FF"> <span class="cir-item-bg-1" style="background-color:#4EABDB ">9</span> </div>
                </div>
                <div class="item-bd key-bg-1 pull-right"> <span class="item-color-1">按9人工服务</span>
                  <div class="cir key-cir-other key-cir-bg-1"> <span class="cir-item-bg-1">9</span> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Commonbanner from '@/components/base/Commonbanner'
export default {
  name: 'Introduce',
  components: {
    Commonbanner
  }
}
</script>
<style scoped src="../../assets/css/introduce.css"></style>
